import layout from "../layout/index.vue";

export const constantRoutes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login/login"),
    meta: {
      requireAuth: false,
      title: "登录",
    },
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/error-page/404"),
    meta: {
      requireAuth: false,
      title: "404",
    },
  },
  {
    path: "/open_platform/:id",
    name: "open",
    component: () => import("@/views/open/index"),
    meta: {
      requireAuth: false,
      title: "开放平台",
    },
  },
  {
    path: "/",
    component: layout,
    children: [
      {
        path: "/",
        // path: '/home',
        name: "Index",
        component: () => import("@/views/Index/index"),
        meta: {
          requireAuth: true,
          title: "首页",
        },
      },
      {
        path: "/plan",
        name: "plan",
        component: () => import("@/views/Index/plan"),
        meta: {
          requireAuth: true,
          title: "自动分配计划",
        },
      },
      {
        path: "/recharge",
        name: "recharge",
        component: () => import("@/views/financial/recharge"),
        meta: {
          requireAuth: true,
          title: "风控充值",
        },
      },
      {
        path: "/rechargedetails/:id",
        name: "rechargedetails",
        component: () => import("@/views/financial/recharge/details"),
        meta: {
          requireAuth: true,
          title: "风控消费详情",
        },
      },
      {
        path: "/extension",
        name: "extension",
        component: () => import("@/views/Index/extension"),
        meta: {
          requireAuth: true,
          title: "H5推广设置",
        },
      },
      {
        path: "/paymentconfigure",
        name: "paymentconfigure",
        component: () => import("@/views/baseSet/paymentconfigure"),
        meta: {
          requireAuth: true,
          title: "基础信息配置",
        },
      },
      {
        path: "/baseSet",
        name: "baseSet",
        component: () => import("@/views/baseSet/index"),
        meta: {
          requireAuth: true,
          title: "基础信息配置",
        },
      },

      {
        path: "/Statistics",
        name: "Statistics",
        component: () => import("@/views/Index/Statistics"),
        meta: {
          requireAuth: true,
          title: "统计",
        },
      },
      {
        path: "/Productlist",
        name: "Productlist",
        component: () => import("@/views/Productlist/index"),
        meta: {
          requireAuth: true,
          title: "商品列表",
        },
      },
      {
        path: "/attractinvestment",
        name: "attractinvestment",
        component: () => import("@/views/attractinvestment/index"),
        meta: {
          requireAuth: true,
          title: "招商",
        },
      },

      {
        path: "/icon",
        name: "icon",
        component: () => import("@/views/icon"),
        meta: {
          requireAuth: true,
          title: "icon",
        },
      },
      {
        path: "/Productlist",
        name: "Productlist",
        component: () => import("@/views/Productlist/index"),
        meta: {
          requireAuth: true,
          title: "商品列表",
        },
      },
      {
        path: "/add",
        name: "add",
        component: () => import("@/views/Productlist/add"),
        meta: {
          requireAuth: true,
          title: "添加商品",
        },
      },

      {
        path: "/goodsaudit",
        name: "goodsaudit",
        component: () => import("@/views/Productlist/goodsaudit"),
        meta: {
          requireAuth: true,
          title: "审核商品",
        },
      },
      {
        path: "/goodsauditnew/:id",
        name: "goodsaudit",
        component: () => import("@/views/Productlist/goodsaudit/new"),
        meta: {
          requireAuth: true,
          title: "审核商品",
        },
      },
      // 提现审核
      {
        path: "/withdrawalAudit",
        name: "withdrawalAudit",
        component: () => import("@/views/examine/withdrawalAudit"),
        meta: {
          requireAuth: true,
          title: "提现审核",
        },
      },
      {
        path: "/rechargelb",
        name: "rechargelb",
        component: () => import("@/views/examine/rechargelb"),
        meta: {
          requireAuth: true,
          title: "充值列表",
        },
      },
      {
        path: "/Productlistdetails/:id",
        name: "details",
        component: () => import("@/views/Productlist/details"),
        meta: {
          requireAuth: true,
          title: "商品详情",
        },
      },
      {
        path: "/editBase/:id",
        name: "editBase",
        component: () => import("@/views/Productlist/editBase/index"),
        meta: {
          requireAuth: true,
          title: "编辑商品信息",
        },
      },
      {
        path: "/mealInfo/:id",
        name: "mealInfo",
        component: () => import("@/views/Productlist/mealInfo/index"),
        meta: {
          requireAuth: true,
          title: "套餐信息",
        },
      },
      {
        path: "/mealInfoeditor/:id",
        name: "mealInfoeditor",
        component: () => import("@/views/Productlist/mealInfo/editor"),
        meta: {
          requireAuth: true,
          title: "编辑套餐",
        },
      },
      ,
      {
        path: "/mealInfoeditoradd/:id/:ids",
        name: "mealInfoeditor",
        component: () => import("@/views/Productlist/mealInfo/add"),
        meta: {
          requireAuth: true,
          title: "添加套餐",
        },
      },

      {
        path: "/articleList",
        name: "articleList",
        component: () => import("@/views/article/articleList"),
        meta: {
          requireAuth: true,
          title: "文章信息列表",
        },
      },
      {
        path: "/infoDispose",
        name: "infoDispose",
        component: () => import("@/views/article/infoDispose"),
        meta: {
          requireAuth: true,
          title: "信息配置",
        },
      },
      {
        path: "/articleList_add",
        name: "articleList_add",
        component: () => import("@/views/article/articleList_add"),
        meta: {
          requireAuth: true,
          title: "添加信息",
        },
      },

      {
        path: "/articleList_add_bj/:id",
        name: "articleList_add_bj",
        component: () => import("@/views/article/articleList_add_bj"),
        meta: {
          requireAuth: true,
          title: "编辑信息",
        },
      },
      {
        path: "/articleList_add_add/:id",
        name: "articleList_add_add",
        component: () => import("@/views/article/articleList_add_add"),
        meta: {
          requireAuth: true,
          title: "添加信息",
        },
      },
      {
        path: "/createArticle",
        name: "createArticle",
        component: () => import("@/views/article/createArticle"),
        meta: {
          requireAuth: true,
          title: "平台发布文章",
        },
      },
      {
        path: "/editArticle/:id",
        name: "editArticle",
        component: () => import("@/views/article/editArticle"),
        meta: {
          requireAuth: true,
          title: "平台文章编辑",
        },
      },
      {
        path: "/service",
        name: "service",
        component: () => import("@/views/service/service"),
        meta: {
          requireAuth: true,
          title: "投诉与建议",
        },
      },
      {
        path: "/viewDetails",
        name: "viewDetails",
        component: () => import("@/views/service/viewDetails"),
        meta: {
          requireAuth: true,
          title: "查看详情",
        },
      },
      {
        path: "/swiper",
        name: "swiper",
        component: () => import("@/views/swiper/index"),
        meta: {
          requireAuth: true,
          title: "首页轮播图",
        },
      },

      // 第三方返回记录
      {
        path: "/userAuthentication",
        name: "userAuthentication",
        component: () => import("@/views/sdkRes/userAuthentication"),
        meta: {
          title: "身份认证列表",
        },
      },
      {
        path: "/orderAuth",
        name: "orderAuth",
        component: () => import("@/views/sdkRes/orderAuth"),
        meta: {
          title: "订单支付列表",
        },
      },

      // 平台
      {
        path: "/regularOrder",
        name: "regularOrder",
        component: () => import("@/views/Order/platformOrder/regularOrder"),
        meta: {
          requireAuth: true,
          title: "常规订单",
        },
      },
      {
        path: "/overdueOrder",
        name: "overdueOrder",
        component: () => import("@/views/Order/platformOrder/overdueOrder"),
        meta: {
          requireAuth: true,
          title: "逾期订单",
        },
      },
      {
        path: "/closingOrder",
        name: "closingOrder",
        component: () => import("@/views/Order/platformOrder/closingOrder"),
        meta: {
          requireAuth: true,
          title: "成交订单",
        },
      },
      {
        path: "/closeOrder",
        name: "closeOrder",
        component: () => import("@/views/Order/platformOrder/closeOrder"),
        meta: {
          requireAuth: true,
          title: "结清订单",
        },
      },
      {
        path: "/billingDetails",
        name: "billingDetails",
        component: () => import("@/views/Order/billingDetails"),
        meta: {
          requireAuth: true,
          title: "账单明细",
        },
      },

      // 权限管理
      {
        path: "/menuList",
        name: "menuList",
        component: () => import("@/views/roleList/menuList"),
        meta: {
          requireAuth: true,
          title: "菜单列表",
        },
      },
      {
        path: "/roleList",
        name: "roleList",
        component: () => import("@/views/roleList/index"),
        meta: {
          requireAuth: true,
          title: "角色列表",
        },
      },
      {
        path: "/addRole",
        name: "addRole",
        component: () => import("@/views/roleList/addRole"),
        meta: {
          requireAuth: true,
          title: "添加角色",
        },
      },
      {
        path: "/updateRole/:id",
        name: "updateRole",
        component: () => import("@/views/roleList/updateRole"),
        meta: {
          requireAuth: true,
          title: "编辑角色",
        },
      },
      {
        path: "/menuList_add",
        name: "menuList_add",
        component: () => import("@/views/roleList/menuList_add"),
        meta: {
          requireAuth: true,
          title: "添加菜单",
        },
      },
      {
        path: "/menuListt_add_bj/:id",
        name: "menuListt_add_bj",
        component: () => import("@/views/roleList/menuListt_add_bj"),
        meta: {
          requireAuth: true,
          title: "编辑菜单",
        },
      },
      {
        path: "/menuListt_add_add/:id",
        name: "menuListt_add_add",
        component: () => import("@/views/roleList/menuListt_add_add"),
        meta: {
          requireAuth: true,
          title: "添加子菜单", //添加子菜单
        },
      },

      // 供应商
      {
        path: "/regularOrderG",
        name: "regularOrderG",
        component: () => import("@/views/Order/supplierOrder/regularOrder"),
        meta: {
          requireAuth: true,
          title: "常规订单",
        },
      },
      {
        path: "/overdueOrderG",
        name: "overdueOrderG",
        component: () => import("@/views/Order/supplierOrder/overdueOrder"),
        meta: {
          requireAuth: true,
          title: "逾期订单",
        },
      },
      {
        path: "/closingOrderG",
        name: "closingOrderG",
        component: () => import("@/views/Order/supplierOrder/closingOrder"),
        meta: {
          requireAuth: true,
          title: "成交订单",
        },
      },
      {
        path: "/closeOrderG",
        name: "closeOrderG",
        component: () => import("@/views/Order/supplierOrder/closeOrder"),
        meta: {
          requireAuth: true,
          title: "结清订单",
        },
      },
      {
        path: "/orderInfo/:id",
        name: "orderInfo",
        component: () => import("@/views/Order/orderInfo"),
        meta: {
          requireAuth: true,
          title: "订单详情",
        },
      },
      {
        path: "/Ordereditor",
        name: "Ordereditor",
        component: () => import("@/views/Order/editor"),
        meta: {
          requireAuth: true,
          title: "编辑套餐",
        },
      },
      {
        path: "/categorylist",
        name: "categorylist",
        component: () => import("@/views/categorylist"),
        meta: {
          requireAuth: true,
          title: "品类列表",
        },
      },
      {
        path: "/infocustom",
        name: "infocustom",
        component: () => import("@/views/categorylist/info_custom"),
        meta: {
          requireAuth: true,
          title: "定义表单项",
        },
      },
      {
        path: "/renovation",
        name: "renovation",
        component: () => import("@/views/categorylist/renovation"),
        meta: {
          requireAuth: true,
          title: "店铺装修",
        },
      },

      {
        path: "/shcategory",
        name: "shcategory",
        component: () => import("@/views/examine/category"),
        meta: {
          requireAuth: true,
          title: "品类审核",
        },
      },
      {
        path: "/shspxj",
        name: "shspxj",
        component: () => import("@/views/examine/goodsaudit"),
        meta: {
          requireAuth: true,
          title: "商品下架",
        },
      },
      // 供应商列表
      {
        path: "/supplierList",
        name: "supplierList",
        component: () => import("@/views/suppliermanagement/supplierList"),
        meta: {
          requireAuth: true,
          title: "供应商管理",
        },
      },
      {
        path: "/applyList",
        name: "applyList",
        component: () => import("@/views/suppliermanagement/applyList"),
        meta: {
          requireAuth: true,
          title: "申请列表",
        },
      },
      {
        path: "/newSupplier",
        name: "newSupplier",
        component: () => import("@/views/suppliermanagement/newSupplier"),
        meta: {
          requireAuth: true,
          title: "新增商户",
        },
      },
      {
        path: "/supplier",
        name: "supplier",
        component: () => import("@/views/examine/supplier"),
        meta: {
          requireAuth: true,
          title: "供应商审核",
        },
      },

      {
        path: "/supplierview/:id",
        name: "supplierview",
        component: () => import("@/views/examine/supplier/supplier_view.vue"),
        meta: {
          requireAuth: true,
          title: "供应商详情",
        },
      },
      {
        path: "/usersList",
        name: "usersList",
        component: () => import("@/views/user/usersList"),
        meta: {
          requireAuth: true,
          title: "账号列表",
        },
      },

      //财务模块
      {
        path: "/Financemodular",
        name: "Financemodular",
        component: () => import("@/views/financial/Financemodular"),
        meta: {
          requireAuth: true,
          title: "风控流水",
        },
      },

      {
        path: "/orderFinancialList",
        name: "orderFinancialList",
        component: () => import("@/views/financial/orderFinancialList"),
        meta: {
          requireAuth: true,
          title: "订单财务列表",
        },
      },
      {
        path: "/billFinancialList",
        name: "billFinancialList",
        component: () => import("@/views/financial/billFinancialList"),
        meta: {
          requireAuth: true,
          title: "账单财务列表",
        },
      },
      {
        path: "/overdueList",
        name: "overdueList",
        component: () => import("@/views/financial/overdueList"),
        meta: {
          requireAuth: true,
          title: "逾期列表",
        },
      },
      {
        path: "/record",
        name: "record",
        component: () => import("@/views/financial/record"),
        meta: {
          requireAuth: true,
          title: "提现记录",
        },
      },
      ,
      {
        path: "/details_1",
        name: "details_1",
        component: () => import("@/views/financial/details_1"),
        meta: {
          requireAuth: true,
          title: "提现流水", //提现成功
        },
      },
      {
        path: "/checkWater",
        name: "checkWater",
        component: () => import("@/views/financial/checkWater"),
        meta: {
          requireAuth: true,
          title: "对账流水",
        },
      },
      {
        path: "/cashpledge",
        name: "cashpledge",
        component: () => import("@/views/cashpledge/index.vue"),
        meta: {
          requireAuth: true,
          title: "押金管理",
        },
      },
    ],
  },
];

/**
 * 需要根据用户角色动态加载的路由
 */

// 所有上面定义的路由都要写在下面的routers里
export let constRouters = [constantRoutes];
